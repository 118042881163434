/**
 * Got tired of a try/catch around this feature everywhere.
 *
 * It has to look like a JSON string or no attempt is made to parse it on the
 * theory it is better to NOT throw an error if it can be prevented.
 *
 * @param jsonString
 * @returns
 */

import { STRING_BOOLEAN_HASH } from '../constants';

// Written by CoPilot.
export function compare({
  obj1,
  obj2,
  options = {
    ignoreLeafKey: {},
    strict: false,
  },
}: {
  obj1: { name?: string; value: any };
  obj2: { name?: string; value: any };
  options?: {
    ignoreLeafKey?: STRING_BOOLEAN_HASH;
    strict?: boolean;
  };
}): any {
  const diffs: any = {},
    obj1Name = obj1.name ?? 'obj1',
    obj2Name = obj2.name ?? 'obj2',
    strict = options?.strict;

  function findDiffs(o1: any, o2: any, path: string = '') {
    for (const key in o1) {
      if (!(key in o2)) {
        if (options?.ignoreLeafKey?.[key]) continue;
        // Sorry, linter hates this, I don't give a sh*t. If you can figure how to turn it off
        // you're smarter than I. I just want to get the job done, not fight with the linter/compiler.
        // @ts-expect-error
        if (!strict && (obj1[key] === null || obj1[key] === undefined)) {
          continue;
        }
        diffs[path + key] = {
          type: `missing_in_${obj2Name}`,
          value: o1[key],
        };
      } else if (typeof o1[key] === 'object' && typeof o2[key] === 'object') {
        findDiffs(o1[key], o2[key], path + key + '.');
      } else if (o1[key] !== o2[key]) {
        if (options?.ignoreLeafKey?.[key]) continue;
        diffs[path + key] = {
          type: 'value_changed',
          value1: o1[key],
          value2: o2[key],
        };
      }
    }

    for (const key in o2) {
      if (!(key in o1)) {
        if (options?.ignoreLeafKey?.[key]) continue;
        // Sorry, linter hates this, I don't give a sh*t.
        // @ts-expect-error
        if (!strict && obj2[key] === null) continue;
        diffs[path + key] = {
          type: `missing_in_${obj1Name}`,
          value: o2[key],
        };
      }
    }
  }

  findDiffs(obj1.value, obj2.value);
  return diffs;
}

const parse = (jsonString: string, def?: any) => {
  let resp = def || null;
  if (!jsonString) {
    return resp;
  }
  if (typeof jsonString === 'object') {
    return jsonString;
  }
  if (typeof jsonString !== 'string') {
    return resp;
  }
  if (jsonString.indexOf('{') !== 0 && jsonString.indexOf('[') !== 0) {
    return resp;
  }

  try {
    resp = JSON.parse(jsonString);
  } catch (e) {
    console.error('Error parsing JSON string:', jsonString);
  }
  return resp;
};

const stringify = (obj: any, def?: string | null) => {
  let resp = def || '';
  if (obj) {
    try {
      resp = JSON.stringify(obj);
    } catch (e) {
      console.error('Error stringifying JSON object:', obj);
    }
  }
  return resp;
};

const pretty = (obj: any, def?: string | null) => {
  let resp = def || '';
  if (obj) {
    try {
      resp = JSON.stringify(obj, null, 2);
    } catch (e) {
      console.error('Error stringifying JSON object:', obj);
    }
  }
  return resp;
};

/**
 * Just seemed we needed this.
 *
 * @param obj
 * @returns
 */
const clone = (obj: any, def?: any) => {
  if (!obj) return def ? def : obj;
  if (typeof obj !== 'object') return def ? def : obj;
  let resp = def || null;
  try {
    resp = JSON.parse(JSON.stringify(obj));
  } catch (e) {
    console.error('Error cloning JSON object:', obj);
  }
  return resp;
};

/**
 * Just in case we need more functions on JSON objects that we use all the
 * time.
 */
export const ChiroUpJSON = {
  clone,
  parse,
  stringify,
  pretty,
  compare,
};
