/**
 * Global constants. It would be nice if the different apps all can use the same
 * fixed constants, like options for selects. Anything that was needed in HC was
 * copied from the CUP-specific tree to here and then the CUP-specific tree just
 * re-exported it to make the move transparent.
 */
import { Genders } from '../enums';
import { Dispatch, SetStateAction } from 'react';
import { IntegrationInvoice } from '../types';
import dayjs from 'dayjs';

export type FunctionStageCallbacks = {
  entry?: (args?: any) => void;
};

export type SELECT_OPTION_TYPE = {
  text: string;
  value: string;
  selected?: boolean;
  disabled?: boolean;
  data?: any;
  iconField?: any;
};

export type NUMBER_ANY_HASH = { [key: number]: any };
export type NUMBER_STRING_HASH = { [key: number]: string };
export type NUMBER_BOOLEAN_HASH = { [key: number]: boolean };
export type NUMBER_NUMBER_HASH = { [key: number]: number };
export type STRING_ANY_HASH = { [key: string]: any };
export type STRING_BOOLEAN_HASH = { [key: string]: boolean };
export type STRING_STRING_HASH = { [key: string]: string };
export type STRING_STRINGS_HASH = { [key: string]: string[] };
export type STRING_NUMBER_HASH = { [key: string]: number };

export interface USE_STATE_PAIR<T> {
  value: T;
  set: Dispatch<SetStateAction<T>>;
}

export enum CRUD_ENUM {
  Create = 'Create',
  Read = 'Read',
  Update = 'Update',
  Delete = 'Delete',
  PrintPatient = 'Patient Invoice Printed',
  EmailPatient = 'Patient Invoice Emailed',
  PrintStatement = 'Printed in Patient Statement',
  PrintReceipt = 'Printed in Patient Receipt',
  CreateBatchEDI = 'Create Batch EDI',
  DownloadBatchEDI = 'Download Batch EDI',
}

export type ACTIVITY_CHANGE_TYPE = {
  field?: string;
  from?: string;
  to?: string;
  msg?: string;
  json?: boolean;
  operation: CRUD_ENUM;
};

export enum EDI_SUBMITTER_TYPE_ENUM {
  Individual = 'Individual',
  Organization = 'Organization',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Default = 'Organization',
}

export const EDI_SUBMITTER_TYPE_OPTIONS = Object.keys(EDI_SUBMITTER_TYPE_ENUM)
  .filter((key) => key !== 'Default')
  .map((key) => ({ text: key, value: key }));

export const LEGAL_GUARDIAN_RELATIONSHIP_OPTIONS = [
  { text: 'Parent', value: 'parent' },
  { text: 'Grandparent', value: 'grandparent' },
  { text: 'Sibling', value: 'sibling' },
  { text: 'Other', value: 'other' },
];

/**
 * For endpoints that will allow you to get multiple IDs at the same
 * time, this is the character that is used to join them together and
 * split them apart.
 */
export const GLOBAL_MANY_IDS_SEP = '+';

export const GLOBAL_PLACE_OF_SERVICE_OPTIONS = [
  { text: '01 - Pharmacy', value: '01' },
  {
    text: '02 - Telehealth Provided Other than in Patient’s Home',
    value: '02',
  },
  { text: '03 - School', value: '03' },
  { text: '04 - Homeless Shelter', value: '04' },
  {
    text: '05 - Indian Health Service Freestanding Facility',
    value: '05',
  },
  {
    text: '06 - Indian Health Service Providerbased Facility',
    value: '06',
  },
  {
    text: '07 - Tribal 638 Freestanding Facility',
    value: '07',
  },
  {
    text: '08 - Tribal 638 Providerbased Facility',
    value: '08',
  },
  { text: '09 - Prison/Correctional Facility', value: '09' },
  {
    text: '10 - Telehealth Provided in Patient’s Home',
    value: '10',
  },
  { text: '11 - Office', value: '11' },
  { text: '12 - Home', value: '12' },
  { text: '13 - Assisted Living Facility', value: '13' },
  { text: '14 - Group Home', value: '14' },
  { text: '15 - Mobile Unit', value: '15' },
  { text: '16 - Temporary Lodging', value: '16' },
  { text: '17 - Walk-in Retail Health Clinic', value: '17' },
  { text: '18 - Place of Employment/Worksite', value: '18' },
  {
    text: '19 - Off Campus-Outpatient Hospital',
    value: '19',
  },
  { text: '20 - Urgent Care Facility', value: '20' },
  { text: '21 - Inpatient Hospital', value: '21' },
  { text: '22 - On Campus-Outpatient Hospital', value: '22' },
  { text: '23 - Emergency Room – Hospital', value: '23' },
  { text: '24 - Ambulatory Surgical Center', value: '24' },
  { text: '25 - Birthing Center', value: '25' },
  { text: '26 - Military Treatment Facility', value: '26' },
  { text: '31 - Skilled Nursing Facility', value: '31' },
  { text: '32 - Nursing Facility', value: '32' },
  { text: '33 - Custodial Care Facility', value: '33' },
  { text: '34 - Hospice', value: '34' },
  { text: '41 - Ambulance – Land', value: '41' },
  { text: '42 - Ambulance – Air or Water', value: '42' },
  { text: '49 - Independent Clinic', value: '49' },
  {
    text: '50 - Federally Qualified Health Center',
    value: '50',
  },
  {
    text: '51 - Inpatient Psychiatric Facility',
    value: '51',
  },
  {
    text: '52 - Psychiatric Facility – Partial Hospitalization',
    value: '52',
  },
  {
    text: '53 - Community Mental Health Center',
    value: '53',
  },
  {
    text: '54 - Intermediate Care Facility/Individuals with Intellectual Disabilities',
    value: '54',
  },
  {
    text: '55 - Residential Substance Abuse Treatment Facility',
    value: '55',
  },
  {
    text: '56 - Psychiatric Residential Treatment Center',
    value: '56',
  },
  {
    text: '57 - Non-residential Substance Abuse Treatment Facility',
    value: '57',
  },
  {
    text: '58 - Non-residential Opioid Treatment Facility',
    value: '58',
  },
  { text: '60 - Mass Immunization Center', value: '60' },
  {
    text: '61 - Comprehensive Inpatient Rehabilitation Facility',
    value: '61',
  },
  {
    text: '62 - Comprehensive Outpatient Rehabilitation Facility',
    value: '62',
  },
  {
    text: '65 - End-Stage Renal Disease Treatment Facility',
    value: '65',
  },
  { text: '71 - Public Health Clinic', value: '71' },
  { text: '72 - Rural Health Clinic', value: '72' },
  { text: '81 - Independent Laboratory', value: '81' },
  { text: '99 - Other Place of Service', value: '99' },
];

export const GLOBAL_PREFIX_OPTIONS = [
  {
    text: 'Dr.',
    value: 'Dr.',
  },
  {
    text: 'Mrs.',
    value: 'Mrs.',
  },
  {
    text: 'Ms.',
    value: 'Ms.',
  },
  {
    text: 'Miss',
    value: 'Miss',
  },
  {
    text: 'Mr.',
    value: 'Mr.',
  },
  {
    text: 'Mx.',
    value: 'Mx.',
  },
];

export const GLOBAL_SUFFIX_OPTIONS = [
  {
    text: 'I',
    value: 'I',
  },
  {
    text: 'II',
    value: 'II',
  },
  {
    text: 'III',
    value: 'III',
  },
  {
    text: 'IV',
    value: 'IV',
  },
  {
    text: 'Jr',
    value: 'Jr',
  },
  {
    text: 'Sr',
    value: 'Sr',
  },
];

export const GLOBAL_PRONOUN_OPTIONS = [
  {
    value: 'He/Him/His',
    text: 'He/Him/His',
  },
  {
    value: 'She/Her/Hers',
    text: 'She/Her/Hers',
  },
  {
    value: 'They/Them/Theirs',
    text: 'They/Them/Theirs',
  },
  {
    value: 'Ze/Hir/Hirs',
    text: 'Ze/Hir/Hirs',
  },
  {
    value: 'Xe/Xem/Xyrs',
    text: 'Xe/Xem/Xyrs',
  },
  {
    value: 'Ey/Em/Eirs',
    text: 'Ey/Em/Eirs',
  },
  {
    value: 'Per/Per/Pers',
    text: 'Per/Per/Pers',
  },
  {
    value: 'Ve/Ver/Vers',
    text: 'Ve/Ver/Vers',
  },
  {
    value: 'Other',
    text: 'Other',
  },
];

export const GLOBAL_PRONOUN_SPANSIH_OPTIONS = [
  {
    value: 'He/Him/His',
    text: 'Él/Él/Su',
  },
  {
    value: 'She/Her/Hers',
    text: 'Ella/Ella/Su',
  },
  {
    value: 'They/Them/Theirs',
    text: 'Ellos/Ellos/Su',
  },
  {
    value: 'Ze/Hir/Hirs',
    text: 'Ze/Hir/Hirs',
  },
  {
    value: 'Xe/Xem/Xyrs',
    text: 'Xe/Xem/Xyrs',
  },
  {
    value: 'Ey/Em/Eirs',
    text: 'Ey/Em/Eirs',
  },
  {
    value: 'Per/Per/Pers',
    text: 'Per/Per/Pers',
  },
  {
    value: 'Ve/Ver/Vers',
    text: 'Ve/Ver/Vers',
  },
  {
    value: 'Other',
    text: 'Otro/otra',
  },
];

export const GLOBAL_SEX_AND_GENDER_OPTIONS = [
  {
    text: 'Male',
    value: Genders.male,
  },
  {
    text: 'Female',
    value: Genders.female,
  },
  {
    text: 'Other',
    value: Genders.other,
  },
  {
    text: 'Prefer not to disclose',
    value: Genders.notDisclosed,
  },
];

export const GLOBAL_SEX_AND_GENDER_SPANISH_OPTIONS = [
  {
    text: 'Hombre',
    value: Genders.male,
  },
  {
    text: 'Mujer',
    value: Genders.female,
  },
  {
    text: 'Otro',
    value: Genders.other,
  },
  {
    text: 'Prefiero no decirlo',
    value: Genders.notDisclosed,
  },
];

export const GLOBAL_BIOLOGICAL_GENDER_OPTIONS = [
  {
    text: 'Male',
    value: Genders.male,
  },
  {
    text: 'Female',
    value: Genders.female,
  },
  {
    text: 'Other',
    value: Genders.other,
  },
];

export const GLOBAL_RACE_SPANISH_OPTIONS = [
  {
    text: 'Indígena americano o nativo de Alaska',
    value: 'American Indian or Alaska Native',
  },
  {
    text: 'Asiático',
    value: 'Asian',
  },
  {
    text: 'Negro o afroamericano',
    value: 'Black or African American',
  },
  {
    text: 'Hispano o latino',
    value: 'Hispanic or Latinx',
  },
  {
    text: 'De Oriente Medio o norteafricano',
    value: 'Middle Eastern or North African',
  },
  {
    text: 'Nativo de Hawái u otro isleño del Pacífico',
    value: 'Native Hawaiian or Other Pacific Islander',
  },
  {
    text: 'Blanco',
    value: 'White',
  },
  {
    text: 'Raza o etnia no especificada',
    value: 'Race or Ethnicity not listed',
  },
];

export const GLOBAL_RACE_OPTIONS = [
  {
    text: 'American Indian or Alaska Native',
    value: 'American Indian or Alaska Native',
  },
  {
    text: 'Asian',
    value: 'Asian',
  },
  {
    text: 'Black or African American',
    value: 'Black or African American',
  },
  {
    text: 'Hispanic or Latinx',
    value: 'Hispanic or Latinx',
  },
  {
    text: 'Middle Eastern or North African',
    value: 'Middle Eastern or North African',
  },
  {
    text: 'Native Hawaiian or Other Pacific Islander',
    value: 'Native Hawaiian or Other Pacific Islander',
  },
  {
    text: 'White',
    value: 'White',
  },
  {
    text: 'Race or Ethnicity not listed',
    value: 'Race or Ethnicity not listed',
  },
];

export const GLOBAL_MORE_LANGUAGE_OPTIONS = [
  { value: 'en', text: 'English' },
  { value: 'es', text: 'Spanish' },
  { value: 'fr', text: 'French' },
  { value: 'de', text: 'German' },
  { value: 'it', text: 'Italian' },
  { value: 'ja', text: 'Japanese' },
  { value: 'ko', text: 'Korean' },
  { value: 'pt', text: 'Portuguese' },
  { value: 'ru', text: 'Russian' },
  { value: 'zh', text: 'Chinese' },
  { value: 'ar', text: 'Arabic' },
  { value: 'bn', text: 'Bengali' },
  { value: 'hi', text: 'Hindi' },
  { value: 'id', text: 'Indonesian' },
  { value: 'ms', text: 'Malay' },
  { value: 'pa', text: 'Punjabi' },
  { value: 'tl', text: 'Tagalog' },
  { value: 'th', text: 'Thai' },
  { value: 'tr', text: 'Turkish' },
  { value: 'vi', text: 'Vietnamese' },
];

export const GLOBAL_COUNTRY_OPTIONS = [
  { text: 'Select a country', value: '' },
  { text: 'United States of America', value: 'USA' },
  { text: 'Afghanistan', value: 'AFG' },
  { text: 'Åland Islands', value: 'ALA' },
  { text: 'Albania', value: 'ALB' },
  { text: 'Algeria', value: 'DZA' },
  { text: 'American Samoa', value: 'ASM' },
  { text: 'Andorra', value: 'AND' },
  { text: 'Angola', value: 'AGO' },
  { text: 'Anguilla', value: 'AIA' },
  { text: 'Antarctica', value: 'ATA' },
  { text: 'Antigua and Barbuda', value: 'ATG' },
  { text: 'Argentina', value: 'ARG' },
  { text: 'Armenia', value: 'ARM' },
  { text: 'Aruba', value: 'ABW' },
  { text: 'Australia', value: 'AUS' },
  { text: 'Austria', value: 'AUT' },
  { text: 'Azerbaijan', value: 'AZE' },
  { text: 'Bahamas', value: 'BHS' },
  { text: 'Bahrain', value: 'BHR' },
  { text: 'Bangladesh', value: 'BGD' },
  { text: 'Barbados', value: 'BRB' },
  { text: 'Belarus', value: 'BLR' },
  { text: 'Belgium', value: 'BEL' },
  { text: 'Belize', value: 'BLZ' },
  { text: 'Benin', value: 'BEN' },
  { text: 'Bermuda', value: 'BMU' },
  { text: 'Bhutan', value: 'BTN' },
  { text: 'Bolivia', value: 'BOL' },
  {
    text: 'Bonaire, Sint Eustatius and Saba',
    value: 'BES',
  },
  { text: 'Bosnia and Herzegovina', value: 'BIH' },
  { text: 'Botswana', value: 'BWA' },
  { text: 'Bouvet Island', value: 'BVT' },
  { text: 'Brazil', value: 'BRA' },
  {
    text: 'British Indian Ocean Territory',
    value: 'IOT',
  },
  { text: 'Brunei Darussalam', value: 'BRN' },
  { text: 'Bulgaria', value: 'BGR' },
  { text: 'Burkina Faso', value: 'BFA' },
  { text: 'Burundi', value: 'BDI' },
  { text: 'Cabo Verde', value: 'CPV' },
  { text: 'Cambodia', value: 'KHM' },
  { text: 'Cameroon', value: 'CMR' },
  { text: 'Canada', value: 'CAN' },
  { text: 'Cayman Islands', value: 'CYM' },
  { text: 'Central African Republic', value: 'CAF' },
  { text: 'Chad', value: 'TCD' },
  { text: 'Chile', value: 'CHL' },
  { text: 'China', value: 'CHN' },
  { text: 'Christmas Island', value: 'CXR' },
  { text: 'Cocos (Keeling) Islands', value: 'CCK' },
  { text: 'Colombia', value: 'COL' },
  { text: 'Comoros', value: 'COM' },
  { text: 'Congo', value: 'COG' },
  {
    text: 'Congo (Democratic Republic of the)',
    value: 'COD',
  },
  { text: 'Cook Islands', value: 'COK' },
  { text: 'Costa Rica', value: 'CRI' },
  { text: "Côte d'Ivoire", value: 'CIV' },
  { text: 'Croatia', value: 'HRV' },
  { text: 'Cuba', value: 'CUB' },
  { text: 'Curaçao', value: 'CUW' },
  { text: 'Cyprus', value: 'CYP' },
  { text: 'Czechia', value: 'CZE' },
  { text: 'Denmark', value: 'DNK' },
  { text: 'Djibouti', value: 'DJI' },
  { text: 'Dominica', value: 'DMA' },
  { text: 'Dominican Republic', value: 'DOM' },
  { text: 'Ecuador', value: 'ECU' },
  { text: 'Egypt', value: 'EGY' },
  { text: 'El Salvador', value: 'SLV' },
  { text: 'Equatorial Guinea', value: 'GNQ' },
  { text: 'Eritrea', value: 'ERI' },
  { text: 'Estonia', value: 'EST' },
  { text: 'Ethiopia', value: 'ETH' },
  {
    text: 'Falkland Islands (Malvinas)',
    value: 'FLK',
  },
  { text: 'Faroe Islands', value: 'FRO' },
  { text: 'Fiji', value: 'FJI' },
  { text: 'Finland', value: 'FIN' },
  { text: 'France', value: 'FRA' },
  { text: 'French Guiana', value: 'GUF' },
  { text: 'French Polynesia', value: 'PYF' },
  {
    text: 'French Southern Territories',
    value: 'ATF',
  },
  { text: 'Gabon', value: 'GAB' },
  { text: 'Gambia', value: 'GMB' },
  { text: 'Georgia', value: 'GEO' },
  { text: 'Germany', value: 'DEU' },
  { text: 'Ghana', value: 'GHA' },
  { text: 'Gibraltar', value: 'GIB' },
  { text: 'Greece', value: 'GRC' },
  { text: 'Greenland', value: 'GRL' },
  { text: 'Grenada', value: 'GRD' },
  { text: 'Guadeloupe', value: 'GLP' },
  { text: 'Guam', value: 'GUM' },
  { text: 'Guatemala', value: 'GTM' },
  { text: 'Guernsey', value: 'GGY' },
  { text: 'Guinea', value: 'GIN' },
  { text: 'Guinea-Bissau', value: 'GNB' },
  { text: 'Guyana', value: 'GUY' },
  { text: 'Haiti', value: 'HTI' },
  { text: 'Honduras', value: 'HND' },
  { text: 'Hong Kong', value: 'HKG' },
  { text: 'Hungary', value: 'HUN' },
  { text: 'Iceland', value: 'ISL' },
  { text: 'India', value: 'IND' },
  { text: 'Indonesia', value: 'IDN' },
  {
    text: 'Iran (Islamic Republic of)',
    value: 'IRN',
  },
  { text: 'Iraq', value: 'IRQ' },
  { text: 'Ireland', value: 'IRL' },
  { text: 'Isle of Man', value: 'IMN' },
  { text: 'Israel', value: 'ISR' },
  { text: 'Italy', value: 'ITA' },
  { text: 'Jamaica', value: 'JAM' },
  { text: 'Japan', value: 'JPN' },
  { text: 'Jersey', value: 'JEY' },
  { text: 'Jordan', value: 'JOR' },
  { text: 'Kazakhstan', value: 'KAZ' },
  { text: 'Kenya', value: 'KEN' },
  { text: 'Kiribati', value: 'KIR' },
  {
    text: "Korea (Democratic People's Republic of)",
    value: 'PRK',
  },
  { text: 'Korea (Republic of)', value: 'KOR' },
  { text: 'Kosovo', value: 'XKX' },
  { text: 'Kuwait', value: 'KWT' },
  { text: 'Kyrgyzstan', value: 'KGZ' },
  {
    text: "Lao People's Democratic Republic",
    value: 'LAO',
  },
  { text: 'Latvia', value: 'LVA' },
  { text: 'Lebanon', value: 'LBN' },
  { text: 'Lesotho', value: 'LSO' },
  { text: 'Liberia', value: 'LBR' },
  { text: 'Libya', value: 'LBY' },
  { text: 'Liechtenstein', value: 'LIE' },
  { text: 'Lithuania', value: 'LTU' },
  { text: 'Luxembourg', value: 'LUX' },
  { text: 'Macau', value: 'MAC' },
  {
    text: 'Macedonia (the former Yugoslav Republic of)',
    value: 'MKD',
  },
  { text: 'Madagascar', value: 'MDG' },
  { text: 'Malawi', value: 'MWI' },
  { text: 'Malaysia', value: 'MYS' },
  { text: 'Maldives', value: 'MDV' },
  { text: 'Mali', value: 'MLI' },
  { text: 'Malta', value: 'MLT' },
  { text: 'Marshall Islands', value: 'MHL' },
  { text: 'Martinique', value: 'MTQ' },
  { text: 'Mauritania', value: 'MRT' },
  { text: 'Mauritius', value: 'MUS' },
  { text: 'Mayotte', value: 'MYT' },
  { text: 'Mexico', value: 'MEX' },
  {
    text: 'Micronesia (Federated States of)',
    value: 'FSM',
  },
  { text: 'Moldova (Republic of)', value: 'MDA' },
  { text: 'Monaco', value: 'MCO' },
  { text: 'Mongolia', value: 'MNG' },
  { text: 'Montenegro', value: 'MNE' },
  { text: 'Montserrat', value: 'MSR' },
  { text: 'Morocco', value: 'MAR' },
  { text: 'Mozambique', value: 'MOZ' },
  { text: 'Myanmar', value: 'MMR' },
  { text: 'Namibia', value: 'NAM' },
  { text: 'Nauru', value: 'NRU' },
  { text: 'Nepal', value: 'NPL' },
  { text: 'Netherlands', value: 'NLD' },
  { text: 'New Caledonia', value: 'NCL' },
  { text: 'New Zealand', value: 'NZL' },
  { text: 'Nicaragua', value: 'NIC' },
  { text: 'Niger', value: 'NER' },
  { text: 'Nigeria', value: 'NGA' },
  { text: 'Niue', value: 'NIU' },
  { text: 'Norfolk Island', value: 'NFK' },
  { text: 'Northern Mariana Islands', value: 'MNP' },
  { text: 'Norway', value: 'NOR' },
  { text: 'Oman', value: 'OMN' },
  { text: 'Pakistan', value: 'PAK' },
  { text: 'Palau', value: 'PLW' },
  { text: 'Palestine, State of', value: 'PSE' },
  { text: 'Panama', value: 'PAN' },
  { text: 'Papua New Guinea', value: 'PNG' },
  { text: 'Paraguay', value: 'PRY' },
  { text: 'Peru', value: 'PER' },
  { text: 'Philippines', value: 'PHL' },
  { text: 'Pitcairn', value: 'PCN' },
  { text: 'Poland', value: 'POL' },
  { text: 'Portugal', value: 'PRT' },
  { text: 'Puerto Rico', value: 'PRI' },
  { text: 'Qatar', value: 'QAT' },
  { text: 'Réunion', value: 'REU' },
  { text: 'Romania', value: 'ROU' },
  { text: 'Russia', value: 'RUS' },
  { text: 'Rwanda', value: 'RWA' },
  { text: 'Saint Barthélemy', value: 'BLM' },
  {
    text: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'SHN',
  },
  { text: 'Saint Kitts and Nevis', value: 'KNA' },
  { text: 'Saint Lucia', value: 'LCA' },
  {
    text: 'Saint Martin (French part)',
    value: 'MAF',
  },
  {
    text: 'Saint Pierre and Miquelon',
    value: 'SPM',
  },
  {
    text: 'Saint Vincent and the Grenadines',
    value: 'VCT',
  },
  { text: 'Samoa', value: 'WSM' },
  { text: 'San Marino', value: 'SMR' },
  { text: 'Sao Tome and Principe', value: 'STP' },
  { text: 'Saudi Arabia', value: 'SAU' },
  { text: 'Senegal', value: 'SEN' },
  { text: 'Serbia', value: 'SRB' },
  { text: 'Seychelles', value: 'SYC' },
  { text: 'Sierra Leone', value: 'SLE' },
  { text: 'Singapore', value: 'SGP' },
  {
    text: 'Sint Maarten (Dutch part)',
    value: 'SXM',
  },
  { text: 'Slovakia', value: 'SVK' },
  { text: 'Slovenia', value: 'SVN' },
  { text: 'Solomon Islands', value: 'SLB' },
  { text: 'Somalia', value: 'SOM' },
  { text: 'South Africa', value: 'ZAF' },
  {
    text: 'South Georgia and the South Sandwich Islands',
    value: 'SGS',
  },
  { text: 'South Sudan', value: 'SSD' },
  { text: 'Spain', value: 'ESP' },
  { text: 'Sri Lanka', value: 'LKA' },
  { text: 'Sudan', value: 'SDN' },
  { text: 'Suriname', value: 'SUR' },
  { text: 'Svalbard and Jan Mayen', value: 'SJM' },
  { text: 'Sweden', value: 'SWE' },
  { text: 'Swaziland', value: 'SWZ' },
  { text: 'Switzerland', value: 'CHE' },
  { text: 'Syrian Arab Republic', value: 'SYR' },
  {
    text: 'Taiwan, Province of China',
    value: 'TWN',
  },
  { text: 'Tajikistan', value: 'TJK' },
  {
    text: 'Tanzania, United Republic of',
    value: 'TZA',
  },
  { text: 'Thailand', value: 'THA' },
  { text: 'Timor-Leste', value: 'TLS' },
  { text: 'Togo', value: 'TGO' },
  { text: 'Tokelau', value: 'TKL' },
  { text: 'Tonga', value: 'TON' },
  { text: 'Trinidad and Tobago', value: 'TTO' },
  { text: 'Tunisia', value: 'TUN' },
  { text: 'Turkey', value: 'TUR' },
  { text: 'Turkmenistan', value: 'TKM' },
  { text: 'Turks and Caicos Islands', value: 'TCA' },
  { text: 'Tuvalu', value: 'TUV' },
  { text: 'Uganda', value: 'UGA' },
  { text: 'Ukraine', value: 'UKR' },
  { text: 'United Arab Emirates', value: 'ARE' },
  {
    text: 'United Kingdom of Great Britain and Northern Ireland',
    value: 'GBR',
  },
  {
    text: 'United States Minor Outlying Islands',
    value: 'UMI',
  },
  { text: 'Uruguay', value: 'URY' },
  { text: 'Uzbekistan', value: 'UZB' },
  { text: 'Vanuatu', value: 'VUT' },
  { text: 'Vatican City', value: 'VAT' },
  {
    text: 'Venezuela (Bolivarian Republic of)',
    value: 'VEN',
  },
  { text: 'Vietnam', value: 'VNM' },
  { text: 'Virgin Islands (British)', value: 'VGB' },
  { text: 'Virgin Islands (U.S.)', value: 'VIR' },
  { text: 'Wallis and Futuna', value: 'WLF' },
  { text: 'Western Sahara', value: 'ESH' },
  { text: 'Yemen', value: 'YEM' },
  { text: 'Zambia', value: 'ZMB' },
  { text: 'Zimbabwe', value: 'ZWE' },
];

export const GLOBAL_PREFERS_OPTIONS = [
  {
    text: 'None',
    value: '',
  },
  {
    text: 'Text message',
    value: 'text',
  },
  {
    text: 'Email',
    value: 'email',
  },
];

export const GLOBAL_CALLING_CODES = [
  '+1',
  '+7',
  '+20',
  '+27',
  '+30',
  '+31',
  '+32',
  '+33',
  '+34',
  '+36',
  '+39',
  '+40',
  '+41',
  '+43',
  '+44',
  '+45',
  '+46',
  '+47',
  '+48',
  '+49',
  '+51',
  '+52',
  '+53',
  '+54',
  '+55',
  '+56',
  '+57',
  '+58',
  '+60',
  '+61',
  '+62',
  '+63',
  '+64',
  '+65',
  '+66',
  '+81',
  '+82',
  '+84',
  '+86',
  '+90',
  '+91',
  '+92',
  '+93',
  '+94',
  '+95',
  '+98',
  '+211',
  '+212',
  '+213',
  '+216',
  '+218',
  '+220',
  '+221',
  '+222',
  '+223',
  '+224',
  '+225',
  '+226',
  '+227',
  '+228',
  '+229',
  '+230',
  '+231',
  '+232',
  '+233',
  '+234',
  '+235',
  '+236',
  '+237',
  '+238',
  '+239',
  '+240',
  '+241',
  '+242',
  '+243',
  '+244',
  '+245',
  '+246',
  '+248',
  '+249',
  '+250',
  '+251',
  '+252',
  '+253',
  '+254',
  '+255',
  '+256',
  '+257',
  '+258',
  '+260',
  '+261',
  '+262',
  '+263',
  '+264',
  '+265',
  '+266',
  '+267',
  '+268',
  '+269',
  '+290',
  '+291',
  '+297',
  '+298',
  '+299',
  '+350',
  '+351',
  '+352',
  '+353',
  '+354',
  '+355',
  '+356',
  '+357',
  '+358',
  '+359',
  '+370',
  '+371',
  '+372',
  '+373',
  '+374',
  '+375',
  '+376',
  '+377',
  '+378',
  '+379',
  '+380',
  '+381',
  '+382',
  '+383',
  '+385',
  '+386',
  '+387',
  '+389',
  '+420',
  '+421',
  '+423',
  '+500',
  '+501',
  '+502',
  '+503',
  '+504',
  '+505',
  '+506',
  '+507',
  '+508',
  '+509',
  '+590',
  '+591',
  '+592',
  '+593',
  '+595',
  '+597',
  '+598',
  '+599',
  '+670',
  '+672',
  '+673',
  '+674',
  '+675',
  '+676',
  '+677',
  '+678',
  '+679',
  '+680',
  '+681',
  '+682',
  '+683',
  '+685',
  '+686',
  '+687',
  '+688',
  '+689',
  '+690',
  '+691',
  '+692',
  '+850',
  '+852',
  '+853',
  '+855',
  '+856',
  '+880',
  '+886',
  '+960',
  '+961',
  '+962',
  '+963',
  '+964',
  '+965',
  '+966',
  '+967',
  '+968',
  '+970',
  '+971',
  '+972',
  '+973',
  '+974',
  '+975',
  '+976',
  '+977',
  '+992',
  '+993',
  '+994',
  '+995',
  '+996',
  '+998',
  '+1-242',
  '+1-246',
  '+1-264',
  '+1-268',
  '+1-284',
  '+1-340',
  '+1-345',
  '+1-441',
  '+1-473',
  '+1-649',
  '+1-664',
  '+1-670',
  '+1-671',
  '+1-684',
  '+1-721',
  '+1-758',
  '+1-767',
  '+1-784',
  '+1-787',
  '+1-939',
  '+1-809',
  '+1-829',
  '+1-849',
  '+1-868',
  '+1-869',
  '+1-876',
  '+44-1481',
  '+44-1534',
  '+44-1624',
];

export const BusinessRuleList = {
  'invoice.0001': {
    description: 'Transition of an invoice from unsubmitted to pending.',
    context: {
      invoice: 'any',
      // targetStatus: 'string',
    },
  },
};

export enum BusinessRuleSeverity {
  Error = 'e',
  Warning = 'w',
  Info = 'i',
}

export type BusinessRuleItemType = {
  text: string;
  path?: string[];
  url?: string;
  solution?: BusinessRuleSolutionEnum;
  tip?: string;
  severity?: BusinessRuleSeverity;
};

export enum BusinessRuleSolutionEnum {
  PrimaryInvoiceWaiting = 'primary-invoice-waiting',
  InvoiceDelayReason = 'invoice-needs-delay-reason',
  LockTransactionServices = 'lock-transaction-services',
}

export type BusinessRuleIssueResponse = {
  count: number;
  issues: BusinessRuleItemType[]; // These are things that _must_ be addressed.
  notes: BusinessRuleItemType[]; // These are warnings or suggestions, etc. Not errors.
  data?: any;
  name?: keyof typeof BusinessRuleSolutionEnum;
  invoice?: IntegrationInvoice;
};

/**
 * This may seem silly, but we now have a choke point if we need to change
 * the dozens of places these are added.
 *
 * @param issues
 * @param text
 * @param url
 */
export const AddBusinessRuleString = (
  issues: BusinessRuleItemType[],
  text: string,
  url?: string,
) => {
  const resp = { text } as any;
  if (url) resp.url = url;
  issues.push(resp);
};

/**
 * Just a convenience. It should work with anything that the JS Date
 * constructor works with. The dob on a patient is yyyy-mm-dd and timestamps
 * are in milliseconds, so we should be good.
 *
 * Should this be somewhere else? Maybe, but there are no "functions" in the
 * root lib folder so did it this way.
 *
 * @param dateOfBirth
 * @returns
 */
export const calculatePatientAge = ({
  dob,
  logErrors = false,
}: {
  dob: string;
  logErrors?: boolean;
}): number => {
  if (!dob) return -1;
  try {
    // Let's not kill the UI or the service if we have an issue.
    const age = dayjs().diff(dayjs(dob), 'year');

    return age;
  } catch (e) {
    if (logErrors) console.error('calculatePatientAge: ', e, dob);
    return -1;
  }
};

export enum InvoiceUseEnum {
  PaymentDetail = 'payment-detail',
}
